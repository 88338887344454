#about {
    padding-top: 5%;
    padding-bottom: 30%;
}

.about-header {
    padding-top: 0;
    padding-bottom: 0;
}

.about-h1 {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    font-family: 'Josefin';
    font-size: 350%;
}

.about-h3 {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    font-family: 'Josefin';
    font-size: 135%;
    padding-bottom: 2%;
}

.about-h2 {
    font-family: 'Roboto Mono';
    color: white;
    font-weight: bold;
}

.about-cont-footer {
    display: flex;
}


.about-cont {
    margin-left: 10rem;
}

.about_img img {
    position: relative;
    width: 110%;
    border-radius: 2rem;
    overflow: hidden;
    transition: var(--transition)
}

.container img:hover {
    transform: scale(1.1);
}

.about-cards {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding-bottom: 4%;
    margin-right: 1%;
}

.about-card {
    background: var(--primary-color-variant);
    border: 1px solid transparent;
    border-radius: 2rem;
    padding: 2rem 1.5rem;
    text-align: center;
    transition: var(--transition);
    backdrop-filter: blur(20px);
}

.about-cont_content .about-card-academic {
    padding: 2rem 0.75rem;
}

.about-cont_content .about-card-experience {
    padding: 2rem 3rem;
}

.about-card:hover {
    background: transparent;
    border-color: var(--primary-color);
    cursor: default;
}

.about-icon {
    font-size: 1.4rem;
    margin-bottom: 0.4rem;
    color: var(--color-light)
}

.about-card small {
    font-family: 'Inter Thin';
    font-size: 0.85rem;
    color: var(--color-white)
}

.paragraph-cont {
    margin-left: 15rem;
    margin-right: 5rem;
    margin-top: 2.5rem;
}

.paragraph-1 {
    font-family: 'Inter Thin';
    font-size: 110%;
    text-align: justify;
    color: white;
}

.paragraph-2 {
    color: rgb(228, 228, 228);
    font-family: 'Inter Thin';
    font-size: 110%;
    text-align: justify;
}

.paragraph-3 {
    color: rgb(204, 204, 204);
    font-family: 'Inter Thin';
    font-size: 110%;
    text-align: justify;
}

/* tablet */
@media screen and (max-width: 1024px) {
    .about-cont {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__cards {
        margin: 1.5rem;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* phone */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        margin: 1.5rem;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}
