header {
  color: white;
  padding: 12% 0 35%;
}

.header-cont h1, .header-cont h2, .header-cont h3 {
  display: flex;
  justify-content: center;
}

.header-cont h1 {
  font-size: 350%;
  font-family: 'Josefin';
}

.header-cont h2 {
  font-family: 'Inter Thin';
  font-size: 115%;
}

.header-cont h3 {
  font-family: 'Josefin';
  font-size: 135%;
  padding-bottom: 0.5%;
}

.header-cont p {
  display: flex;
  justify-content: center;
  font-family: 'Inter Thin';
  font-size: 105%;

}

.CTA {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.CTA a {
  font-family: 'Roboto Condensed';
  font-size: 85%;
  color: var(--color-light);
  border-color: var(--color-light);
}

.CTA .resume-el {
  padding: 0.6rem 1.9rem;
}

.socials {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.socials-el {
  color: white;
  font-size: 120%;
}

.socials-el:hover {
  color: var(--color-light);
}