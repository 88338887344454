nav {
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    padding: 0.7rem 2rem;
    transform: translateX(-50%);
    bottom: 3.5rem;
    left: 50%;
    gap: 1rem;
    border-radius: 2rem;
    backdrop-filter: blur(5px);
    z-index: 9999;

}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 100%;
    display: flex;
    color: var(--color-white);
    font-size: 1.2rem;
}

nav a:hover {
    background: rgba(184, 184, 184, 0.95);
    color: var(--color-black);
} 


nav a.active {
    background: rgba(184, 184, 184, 0.95);
    color: var(--color-black);
}

.icons {
    color: white;
}